<template>
  <section class="delivery" id="delivery">
    <div class="container">
      <div class="delivery-wrapper">
          <img
            class="delivery-picture"
            src="https://wp.prihol.ru/wp-content/themes/phbar/img/picture.svg"
            alt=""
          >
        <picture>
          <source srcset="https://wp.prihol.ru/wp-content/themes/phbar/img/delivery-1.webp"
                  type="image/webp">
          <img class="delivery-img lazyload webpexpress-processed"
               src="https://wp.prihol.ru/wp-content/themes/phbar/img/delivery-1.jpg" alt="" loading="lazy">
        </picture>
        <div class="delivery-title delivery-index">{{ $t('deliveryTitle') }}</div>
        <div class="delivery-subtitle delivery-index">
          {{ $t('deliveryInfo') }}
        </div>
        <!-- <div class="delivery-links delivery-index">
           <div class="delivery-box">
             <a class="delivery-links__phone" href="tel:+78432923001">+7 (843) 292-30-01</a>
           </div>
           </div> -->
        <!-- <a class="delivery-phone delivery-index" href="tel:+79656182433">+7 (965) 618-24-33</a> -->
        <div class="delivery-flex">
          <div class="delivery-flex__block">
            <a class="delivery-phone delivery-index" v-if="deliveryPhone" href="'tel:' + deliveryPhone">{{ deliveryPhone }}</a>
          </div>
          <div class="delivery-flex__block">
            <a :href="getWLink" class="delivery-whatsapp" target="_blank">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1303_10245)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.61 9.588C11.4113 9.48867 10.4373 9.01 10.256 8.94333C10.0747 8.87733 9.94267 8.84466 9.81 9.04333C9.678 9.24067 9.29867 9.68733 9.18333 9.81933C9.06733 9.952 8.952 9.968 8.754 9.86933C8.556 9.76933 7.91733 9.56067 7.16067 8.886C6.572 8.36067 6.174 7.712 6.05867 7.51333C5.94333 7.31533 6.046 7.208 6.14533 7.10933C6.23467 7.02066 6.34333 6.878 6.44267 6.76266C6.542 6.64667 6.57467 6.564 6.64067 6.43133C6.70733 6.29933 6.674 6.184 6.624 6.08466C6.57467 5.98533 6.17867 5.01 6.01333 4.61333C5.85267 4.22733 5.68933 4.28 5.568 4.27333C5.452 4.268 5.32 4.26666 5.188 4.26666C5.056 4.26666 4.84133 4.316 4.66 4.51466C4.478 4.71266 3.96667 5.192 3.96667 6.16733C3.96667 7.142 4.676 8.084 4.77533 8.21667C4.87467 8.34867 6.172 10.35 8.15933 11.208C8.63267 11.412 9.00133 11.534 9.28867 11.6247C9.76333 11.776 10.1953 11.7547 10.5367 11.7033C10.9167 11.6467 11.7087 11.224 11.874 10.7613C12.0387 10.2987 12.0387 9.902 11.9893 9.81933C11.94 9.73667 11.808 9.68733 11.6093 9.588H11.61ZM7.99533 14.5233H7.99267C6.81229 14.5235 5.65358 14.2062 4.638 13.6047L4.398 13.462L1.90333 14.1167L2.56933 11.6847L2.41267 11.4353C1.75276 10.3849 1.40353 9.16918 1.40533 7.92866C1.40667 4.29533 4.36267 1.33933 7.998 1.33933C9.758 1.33933 11.4127 2.026 12.6567 3.27133C13.2703 3.88238 13.7566 4.60904 14.0875 5.40926C14.4185 6.20948 14.5874 7.06738 14.5847 7.93333C14.5833 11.5667 11.6273 14.5233 7.99533 14.5233ZM13.6033 2.32533C12.8688 1.58595 11.9948 0.999688 11.0321 0.600531C10.0693 0.201373 9.03689 -0.00274802 7.99467 -3.19921e-06C3.62533 -3.19921e-06 0.068 3.55666 0.0666667 7.928C0.066 9.32533 0.430667 10.6893 1.12467 11.8913L0 16L4.20267 14.8973C5.36525 15.5307 6.66806 15.8626 7.992 15.8627H7.99533C12.3647 15.8627 15.922 12.306 15.9233 7.934C15.9266 6.89218 15.7232 5.86006 15.325 4.89735C14.9267 3.93464 14.3416 3.06044 13.6033 2.32533Z" fill="white"/>
                </g>
                <defs>
                <clipPath id="clip0_1303_10245">
                <rect width="16" height="16" fill="white"/>
                </clipPath>
                </defs>
              </svg>
              <span>WhatsApp</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { useStore } from 'vuex'
import { computed } from 'vue'
const store = useStore()
const deliveryPhone = computed(() => store.getters.getContacts?.delivery_phone)
const getWLink = computed(() => {
  const phone = store.getters.getContacts?.whatsapp || ''
  return `https://wa.me/${phone.replace(/\D/g, '')}`
})
</script>
