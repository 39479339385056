<template>
  <footer class="footer" id="contact">
    <div class="map" id="map"></div>
    <div class="container">
      <div class="footer-list"  v-if="getContacts">
        <a class="footer-logo" href="/">
          <picture>
            <source srcset="https://wp.prihol.ru/wp-content/webp-express/webp-images/uploads/2023/07/logo-footer.png.webp"
                    type="image/webp">
            <img class="img lazyload webpexpress-processed" src="https://wp.prihol.ru/wp-content/uploads/2023/07/logo-footer.png"
                 alt="">
          </picture>
        </a>
        <div class="footer-info" >
          <div class="footer-info__address">{{ getAddress }}</div>
          <div class="footer-info__level">{{ getFloor }}</div>
        </div>
        <div class="footer-time__item" v-for="i in getContacts.raspisanie" :key="i.time">
            <p>{{ i.time }}</p>
            <span>{{ $i18n.locale == 'ru' ? i.day_ru : i.day_en}}</span>
        </div>
        <a class="footer-email" :href="'mailto:' + getEmail || ''">{{getEmail || ''}}</a>
        <div class="footer-phone">
          <a v-for="phone in getPhones" :key="phone" :href="'tel:' + phone" class="footer-phone__item">
            {{phone}}
          </a>
          <a class="footer-phone__email" :href="'mailto:' + getContacts?.email || ''">{{getContacts?.email || ''}}</a>
        </div>
      </div>
    </div>
    <div class="footer-center">
      <a class="footer-developer" href="https://kmdigital.ru" target="_blank">
        <span>Разработано в </span>
        <img class="img lazyload" src="https://wp.prihol.ru/wp-content/themes/phbar/img/developer.svg" alt="">
      </a>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'TheFooter',
  computed: {
    ...mapGetters(['getContacts']),
    getFloor () {
      return this.getContacts?.floor + this.$i18n.t('siteFloor')
    },
    getAddress () {
      if (this.$i18n.locale === 'en') {
        return this.getContacts?.address_en
      }
      return this.getContacts?.address
    },
    getPhones () {
      if (this.getContacts?.phones) {
        return Object.values(this.getContacts.phones)
      }
      return []
    },
    getEmail () {
      return this.getContacts?.email || ''
    },
    getWorkDays () {
      if (this.$i18n.locale === 'en') {
        return this.getContacts?.address_en
      }
      return this.getContacts?.raspisanie || []
    }
  },
  mounted () {
    // eslint-disable-next-line no-undef
    ymaps.ready(init)

    function init () {
      // eslint-disable-next-line no-undef
      const myMap = new ymaps.Map(
        'map', {
          center: [55.790661, 49.107384],
          zoom: 16,
          controls: []
        }, {
          suppressMapOpenBlock: true
        }
      )

      // eslint-disable-next-line no-undef
      const myPlacemark = new ymaps.Placemark(
        myMap.getCenter(), {
          hintContent: 'г. Казань, Чернышевского, 27а',
          balloonContent: 'г. Казань, Чернышевского, 27а'
        }, {
          iconLayout: 'default#image',
          iconImageHref: 'https://wp.prihol.ru/wp-content/themes/phbar/img/marker.svg',
          iconImageSize: [65, 65],
          iconImageOffset: [-32.5, -32.5]
        }
      )
      myMap.behaviors.disable('scrollZoom')
      myMap.geoObjects.add(myPlacemark)
    }
  }
}
</script>

<style scoped>

</style>
