<template>
  <div class="catalog-left" v-if="menuItems && subMenu">
    <div class="catalog-nav catalog_menu">
      <ul>
        <li v-for="item in subMenu" :key="item.cat_ID" class="menu-item" :class="{
          'active': mainCategory == item.cat_ID,
          'parent': item.sub
        }"
        >
          <div class="menu-item-content" >
            <a class="menu-link" @click.stop="() => onMenuItemClick(item, 'main')"
              :class="{ 'active': currentMenuItemId == item.cat_ID }">
              {{ item.name }}
            </a>
            <span v-if="hasSubItems(item)" class="arrow" @click.stop="() => arrowClick(item, 'main')" :class="{
              'active': mainCategory == item.cat_ID
            }"></span>
          </div>
          <ul class="catalog_menu-submenu" v-if="mainCategory == item.cat_ID && hasSubItems(item)">
            <li v-for="subItem in item.sub" :key="subItem.cat_ID" class="submenu-item" >
              <div class="menu-item-content">
                <a class="menu-link"
                  @click.stop="() => onMenuItemClick(subItem, 'sub')"
                  :class="{ 'active': currentMenuItemId == subItem.cat_ID }">
                  {{ subItem.name }}
                </a>
                <span v-if="hasSubItems(subItem)"  @click.stop="() => arrowClick(subItem, 'sub')" class="arrow" :class="{
                  'active': subCategory == subItem.cat_ID
                }"></span>
              </div>
              <ul v-if="subCategory == subItem.cat_ID" class="catalog_menu-subsubmenu">
                <li v-for="subSubItem in subItem.sub" :key="subSubItem.cat_ID" class="sub-submenu-item" >
                  <div class="menu-item-content">
                    <a class="menu-link" @click.stop="() => onMenuItemClick(subSubItem, 'subSub')"
                      :class="{ 'active': currentMenuItemId == subSubItem.cat_ID }">
                      {{ subSubItem.name }}
                    </a>
                    <span v-if="hasSubItems(subSubItem)" @click.stop="() => arrowClick(subSubItem, 'subSub')"
                      class="arrow" :class="{
                        'active': subSubCategory == subSubItem.cat_ID
                      }"></span>
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'LeftMenu',
  emits: ['menuClick'],
  data () {
    return {
      lastCategory: null,
      mainCategory: null,
      subCategory: null,
      subSubCategory: null
    }
  },
  watch: {
    topMenuCategoryId: {
      immediate: true,
      handler (id, oldId) {
        if (id !== oldId) {
          this.setCurrentCategoryId(null)
          this.mainCategory = null
        }
        this.setCurrentCategoryId(id)
        if (!this.mainCategory && this.subMenu && this.subMenu.length) {
          this.mainCategory = this.subMenu[0].cat_ID
        }
      }
    }
  },
  methods: {
    ...mapActions(['setCategoryName', 'fetchDishes', 'setCurrentCategoryId', 'setCurrentMenuItemId']),
    hasSubItems (item) {
      if (!item || !item.sub) {
        return false
      }
      return item.sub && item.sub.length
    },
    arrowClick (item, cat = 'main') {
      if (!item || !item.cat_ID) {
        return
      }

      const id = item.cat_ID

      if (!id) {
        return
      }

      if (cat === 'main') {
        if (this.mainCategory === id) {
          this.mainCategory = null
        } else {
          this.mainCategory = id
        }
        this.subCategory = null
        this.subSubCategory = null
      } else if (cat === 'sub') {
        if (this.subCategory === id) {
          this.subCategory = null
        } else {
          this.subCategory = id
        }
        this.subSubCategory = null
      } else if (cat === 'subSub') {
        this.subSubCategory = id
      }
    },

    onMenuItemClick (item, cat = 'main') {
      if (!item || !item.cat_ID) {
        return
      }

      const id = item.cat_ID
      const name = item.name
      // this.arrowClick(item, cat)
      this.setCurrentMenuItemId(id)
      this.$emit('menuClick')
      // refetch only if category changed
      if (!this.lastCategory || this.lastCategory !== id) {
        this.setCategoryName(name)
        this.setCurrentCategoryId(id)
        this.fetchDishes(id)
      }
      this.lastCategory = id
    }
  },
  computed: {
    ...mapGetters(['menuItems', 'currentMenuItemId', 'topCategoryName', 'topMenuCategoryId']),
    subMenu () {
      if (!this.topMenuCategoryId) {
        return null
      }
      if (!this.menuItems) {
        return null
      }
      const result = this.menuItems.find(item => item.cat_ID === this.topMenuCategoryId)
      if (!result) {
        return null
      }
      if (result.sub && result.sub.length) {
        return result.sub
      }
      return null
    }
  }
}
</script>
<style>
.menu-item-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu-item-content a.active {
  color: #AB7F55;
}

.menu-item-content .arrow {
  content: "";
  width: 14px;
  height: 9px;
  padding-right: 10px;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  background-image: url("../assets/arrow.svg");
  cursor: pointer;
}

.menu-item-content .arrow.active {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
</style>
