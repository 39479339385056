<template>
  <div class="mobileCatalog" v-if="this.$store.state.mobileMenu">
    <div class="mobileCatalog-top">
      <div class="mobileCatalog-top__title">{{ $t('menuSection') }}</div>
      <div class="mobileCatalog-top__close" @click.prevent="closeMobileMenu">
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.3">
            <path d="M21 1L1 21M1 1L21 21" stroke="#0B1315" stroke-width="2" stroke-linecap="round"></path>
          </g>
        </svg>
      </div>
    </div>
    <TheSearch @search="onSearch"/>
    <nav class="mobileCatalog-nav catalog_menu">
      <ul>
        <li
          v-for="item in menuItems"
          :key="item.cat_ID"
          class="menu-item"
          :class="{
            'active': activeMenuItems.includes(item.cat_ID)
          }"
        >
          <div class="menu-item__mobile-content">
            <a @click.stop="() => onMenuItemClick(item)" :class="{
              'active': activeMenuItems.includes(item.cat_ID)
              }">{{ item.name }}</a>
            <span v-if="hasSubItems(item)" @click.stop="() => arrowClick(item.cat_ID)" class="arrow" :class="{
                'active': activeMenuItems.includes(item.cat_ID)
              }"></span>
          </div>
          <ul v-if="hasSubItems(item) && activeMenuItems.includes(item.cat_ID)">
            <RecurciveMobileMenuItem :activeMenuItems="activeMenuItems" v-for="subItem in item.sub" :key="subItem.cat_ID" @menu-clicked="onMenuItemClick" @arrowClick="arrowClick" :item="subItem"></RecurciveMobileMenuItem>
          </ul>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import TheSearch from '@/components/TheSearch'
import RecurciveMobileMenuItem from '@/components/RecurciveMobileMenuItem'
export default {
  components: {
    TheSearch,
    RecurciveMobileMenuItem
  },
  emits: ['search'],
  data () {
    return {
      activeMenuItems: []
    }
  },
  methods: {
    ...mapActions(['setCategoryName', 'setTopMenuCategoryId', 'fetchDishes', 'setCurrentCategoryId', 'setCurrentMenuItemId']),
    hasSubItems (item) {
      if (!item || !item.sub) {
        return false
      }
      return item.sub && item.sub.length
    },
    closeMobileMenu () {
      this.$store.dispatch('SET_MOBILE_MENU', !this.$store.state.mobileMenu)
    },
    onSearch () {
      this.closeMobileMenu()
      setTimeout(() => {
        this.$emit('search')
      }, 100)
    },
    arrowClick (id) {
      if (!id) {
        return
      }
      if (this.activeMenuItems.includes(id)) {
        const index = this.activeMenuItems.indexOf(id)

        if (index !== -1) {
          this.activeMenuItems.splice(index, 1)
        }
        return
      }
      this.activeMenuItems.push(id)
    },
    onMenuItemClick (item) {
      if (!item) return
      const id = item.cat_ID
      const name = item.name
      this.setTopMenuCategoryId(id)
      this.setCurrentCategoryId(null)
      this.setCurrentMenuItemId(id)
      this.setCategoryName(name)
      this.fetchDishes(id)
      this.setCurrentCategoryId(id)
      this.closeMobileMenu()
      this.$emit('menuClick', 140)
      this.activeMenuItems = []
    }
  },
  computed: {
    ...mapState(['topMenuCategoryId', 'menuItems', 'topMenuCategoryId'])
  }
}
</script>

<style>
  .menu-item__mobile-content{
      display: flex;
      justify-content: space-between;
      align-items: center;
  }
  .menu-item__mobile-content a.active{
      color: #AB7F55;
  }
  .menu-item__mobile-content .arrow{
      content: "";
      width: 14px;
      height: 9px;
      padding-right: 10px;
      -webkit-transition: all 0.4s;
      -o-transition: all 0.4s;
      transition: all 0.4s;
      background-image: url("../assets/arrow.svg");
      cursor: pointer;
  }
  .menu-item__mobile-content .arrow.active{
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
</style>
