<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 0 0" width="0" height="0" focusable="false" role="none"
       style="visibility: hidden; position: absolute; left: -9999px; overflow: hidden;">
    <defs>
      <filter id="wp-duotone-dark-grayscale">
        <feColorMatrix color-interpolation-filters="sRGB" type="matrix"
                       values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "/>
        <feComponentTransfer color-interpolation-filters="sRGB">
          <feFuncR type="table" tableValues="0 0.49803921568627"/>
          <feFuncG type="table" tableValues="0 0.49803921568627"/>
          <feFuncB type="table" tableValues="0 0.49803921568627"/>
          <feFuncA type="table" tableValues="1 1"/>
        </feComponentTransfer>
        <feComposite in2="SourceGraphic" operator="in"/>
      </filter>
    </defs>
  </svg>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 0 0" width="0" height="0" focusable="false" role="none"
       style="visibility: hidden; position: absolute; left: -9999px; overflow: hidden;">
    <defs>
      <filter id="wp-duotone-grayscale">
        <feColorMatrix color-interpolation-filters="sRGB" type="matrix"
                       values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "/>
        <feComponentTransfer color-interpolation-filters="sRGB">
          <feFuncR type="table" tableValues="0 1"/>
          <feFuncG type="table" tableValues="0 1"/>
          <feFuncB type="table" tableValues="0 1"/>
          <feFuncA type="table" tableValues="1 1"/>
        </feComponentTransfer>
        <feComposite in2="SourceGraphic" operator="in"/>
      </filter>
    </defs>
  </svg>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 0 0" width="0" height="0" focusable="false" role="none"
       style="visibility: hidden; position: absolute; left: -9999px; overflow: hidden;">
    <defs>
      <filter id="wp-duotone-purple-yellow">
        <feColorMatrix color-interpolation-filters="sRGB" type="matrix"
                       values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "/>
        <feComponentTransfer color-interpolation-filters="sRGB">
          <feFuncR type="table" tableValues="0.54901960784314 0.98823529411765"/>
          <feFuncG type="table" tableValues="0 1"/>
          <feFuncB type="table" tableValues="0.71764705882353 0.25490196078431"/>
          <feFuncA type="table" tableValues="1 1"/>
        </feComponentTransfer>
        <feComposite in2="SourceGraphic" operator="in"/>
      </filter>
    </defs>
  </svg>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 0 0" width="0" height="0" focusable="false" role="none"
       style="visibility: hidden; position: absolute; left: -9999px; overflow: hidden;">
    <defs>
      <filter id="wp-duotone-blue-red">
        <feColorMatrix color-interpolation-filters="sRGB" type="matrix"
                       values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "/>
        <feComponentTransfer color-interpolation-filters="sRGB">
          <feFuncR type="table" tableValues="0 1"/>
          <feFuncG type="table" tableValues="0 0.27843137254902"/>
          <feFuncB type="table" tableValues="0.5921568627451 0.27843137254902"/>
          <feFuncA type="table" tableValues="1 1"/>
        </feComponentTransfer>
        <feComposite in2="SourceGraphic" operator="in"/>
      </filter>
    </defs>
  </svg>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 0 0" width="0" height="0" focusable="false" role="none"
       style="visibility: hidden; position: absolute; left: -9999px; overflow: hidden;">
    <defs>
      <filter id="wp-duotone-midnight">
        <feColorMatrix color-interpolation-filters="sRGB" type="matrix"
                       values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "/>
        <feComponentTransfer color-interpolation-filters="sRGB">
          <feFuncR type="table" tableValues="0 0"/>
          <feFuncG type="table" tableValues="0 0.64705882352941"/>
          <feFuncB type="table" tableValues="0 1"/>
          <feFuncA type="table" tableValues="1 1"/>
        </feComponentTransfer>
        <feComposite in2="SourceGraphic" operator="in"/>
      </filter>
    </defs>
  </svg>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 0 0" width="0" height="0" focusable="false" role="none"
       style="visibility: hidden; position: absolute; left: -9999px; overflow: hidden;">
    <defs>
      <filter id="wp-duotone-magenta-yellow">
        <feColorMatrix color-interpolation-filters="sRGB" type="matrix"
                       values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "/>
        <feComponentTransfer color-interpolation-filters="sRGB">
          <feFuncR type="table" tableValues="0.78039215686275 1"/>
          <feFuncG type="table" tableValues="0 0.94901960784314"/>
          <feFuncB type="table" tableValues="0.35294117647059 0.47058823529412"/>
          <feFuncA type="table" tableValues="1 1"/>
        </feComponentTransfer>
        <feComposite in2="SourceGraphic" operator="in"/>
      </filter>
    </defs>
  </svg>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 0 0" width="0" height="0" focusable="false" role="none"
       style="visibility: hidden; position: absolute; left: -9999px; overflow: hidden;">
    <defs>
      <filter id="wp-duotone-purple-green">
        <feColorMatrix color-interpolation-filters="sRGB" type="matrix"
                       values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "/>
        <feComponentTransfer color-interpolation-filters="sRGB">
          <feFuncR type="table" tableValues="0.65098039215686 0.40392156862745"/>
          <feFuncG type="table" tableValues="0 1"/>
          <feFuncB type="table" tableValues="0.44705882352941 0.4"/>
          <feFuncA type="table" tableValues="1 1"/>
        </feComponentTransfer>
        <feComposite in2="SourceGraphic" operator="in"/>
      </filter>
    </defs>
  </svg>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 0 0" width="0" height="0" focusable="false" role="none"
       style="visibility: hidden; position: absolute; left: -9999px; overflow: hidden;">
    <defs>
      <filter id="wp-duotone-blue-orange">
        <feColorMatrix color-interpolation-filters="sRGB" type="matrix"
                       values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "/>
        <feComponentTransfer color-interpolation-filters="sRGB">
          <feFuncR type="table" tableValues="0.098039215686275 1"/>
          <feFuncG type="table" tableValues="0 0.66274509803922"/>
          <feFuncB type="table" tableValues="0.84705882352941 0.41960784313725"/>
          <feFuncA type="table" tableValues="1 1"/>
        </feComponentTransfer>
        <feComposite in2="SourceGraphic" operator="in"/>
      </filter>
    </defs>
  </svg>
  <header class="header">
    <div class="container">
      <div class="header-list">
        <nav class="header-nav js-scrolls">
          <ul>
            <li><a href="#catalog" >{{ $t('menuSection') }}</a></li>
            <li><a href="#gallerySection" >{{ $t('menuInterior') }}</a></li>
            <li><a href="#delivery">{{ $t('menuDelivery') }}</a></li>
            <li><a href="#contact">{{ $t('menuContact') }}</a></li>
            <li><a href="#parking">{{ $t('menuParking') }}</a></li>
          </ul>
        </nav>
        <div class="header-phone">
          <a class="header-phone__link" v-for="phone in getPhones" :key="phone" :href="'tel:' + phone" >{{ phone }}</a>
        </div>
        <div class="header-lang_2">
          <!--<div class="header-lang__current">{{ $i18n.locale.toUpperCase() }}</div>-->
          <div class="header-lang__flex">
            <a
              class="header-lang__btn"
              :class="{'active': $i18n.locale === 'ru'}"
              @click="() => changeLanguage('ru')"
            >
            ру
            </a>
            <a
              class="header-lang__btn"
              :class="{'active': $i18n.locale === 'en'}"
              @click="() => changeLanguage('en')"
            >
            eng
            </a>
          </div>
        </div>
      </div>
      <div class="header-mobile">
        <div class="header-hamburger" @click="toggleMobileTopMenu">
          <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="2" rx="1" fill="white"/>
            <rect y="7" width="24" height="2" rx="1" fill="white"/>
            <rect y="14" width="24" height="2" rx="1" fill="white"/>
          </svg>
        </div>
        <!--<div class="header-lang header-lang_2">
          <div class="header-lang__current">{{ $i18n.locale.toUpperCase() }}</div>
          <div class="header-lang__menu">
            <a
              class="header-lang__link"
              :class="{'header-lang__current': $i18n.locale === 'ru'}"
              @click="() => changeLanguage('ru')"
            >
              RU
            </a>
            <a
              class="header-lang__link "
              :class="{'header-lang__current': $i18n.locale === 'en'}"
              @click="() => changeLanguage('en')"
            >
              EN
            </a>
          </div>
        </div>-->
        <a class="header-logo" href="/">
          <picture>
            <source srcset="https://wp.prihol.ru/wp-content/webp-express/webp-images/uploads/2023/07/mobile-logo.png.webp"
                    type="image/webp">
            <img class="img lazyload webpexpress-processed" src="https://wp.prihol.ru/wp-content/uploads/2023/07/mobile-logo.png"
                 alt="">
          </picture>
        </a>
        <div class="header-mobileCatalogBtn" @click="toggleMobileMenu">
          <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M9.04167 11.375H5.83333C4.54417 11.375 3.5 10.3308 3.5 9.04167V5.83333C3.5 4.54417 4.54417 3.5 5.83333 3.5H9.04167C10.3308 3.5 11.375 4.54417 11.375 5.83333V9.04167C11.375 10.3308 10.3308 11.375 9.04167 11.375Z"
                  stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M22.1667 11.375H18.9583C17.6692 11.375 16.625 10.3308 16.625 9.04167V5.83333C16.625 4.54417 17.6692 3.5 18.9583 3.5H22.1667C23.4558 3.5 24.5 4.54417 24.5 5.83333V9.04167C24.5 10.3308 23.4558 11.375 22.1667 11.375Z"
                  stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M9.04167 24.5H5.83333C4.54417 24.5 3.5 23.4558 3.5 22.1667V18.9583C3.5 17.6692 4.54417 16.625 5.83333 16.625H9.04167C10.3308 16.625 11.375 17.6692 11.375 18.9583V22.1667C11.375 23.4558 10.3308 24.5 9.04167 24.5Z"
                  stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M22.1667 24.5H18.9583C17.6692 24.5 16.625 23.4558 16.625 22.1667V18.9583C16.625 17.6692 17.6692 16.625 18.9583 16.625H22.1667C23.4558 16.625 24.5 17.6692 24.5 18.9583V22.1667C24.5 23.4558 23.4558 24.5 22.1667 24.5Z"
                  stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
        <a class="header-mobile__phone" :href="getPhones.length ? 'tel:' +  getPhones[0] :''">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_501_5054)">
              <path
                d="M23.6665 19.9568L20.2828 23.3134C19.7833 23.8257 19.1212 23.9992 18.4765 24C15.625 23.9146 12.9298 22.5139 10.7168 21.0757C7.0844 18.4331 3.75148 15.1564 1.65968 11.1966C0.857404 9.53618 -0.0839564 7.41756 0.0059836 5.56418C0.0140036 4.86698 0.201884 4.18272 0.692904 3.73332L4.07662 0.351297C4.77926 -0.246363 5.45876 -0.0397228 5.9084 0.656437L8.63062 5.81848C8.91714 6.43002 8.75278 7.08526 8.3253 7.52224L7.07866 8.76824C7.0017 8.87366 6.9527 8.99366 6.95146 9.12426C7.4295 10.9747 8.87772 12.6802 10.1571 13.854C11.4364 15.0277 12.8116 16.6179 14.5966 16.9944C14.8173 17.0559 15.0875 17.0776 15.2454 16.9308L16.6956 15.456C17.1955 15.0771 17.9173 14.8919 18.451 15.2017H18.4764L23.3866 18.1005C24.1075 18.5524 24.1826 19.4256 23.6665 19.9568Z"
                fill="white"/>
            </g>
            <defs>
              <clipPath id="clip0_501_5054">
                <rect width="24" height="24" fill="white"/>
              </clipPath>
            </defs>
          </svg>
        </a>
      </div>
    </div>
  </header>
  <div class="mobileMenu" v-show="this.$store.state.mobileMenuTop">
    <div class="mobileMenu-container">
      <div class="header-mobile">
        <div class="header-hamburger" @click="toggleMobileTopMenu">
          <svg width="24" height="16" viewBox="0 0 24 16" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="2" rx="1" fill="white"/>
            <rect y="7" width="24" height="2" rx="1" fill="white"/>
            <rect y="14" width="24" height="2" rx="1" fill="white"/>
          </svg>
        </div>
        <a class="header-logo" href="/">
          <picture>
            <source srcset="https://wp.prihol.ru/wp-content/webp-express/webp-images/uploads/2023/07/mobile-logo.png.webp"
                    type="image/webp">
            <img class="img lazyload webpexpress-processed" src="https://wp.prihol.ru/wp-content/uploads/2023/07/mobile-logo.png"
                 alt="">
          </picture>
        </a>
        <a class="header-mobile__phone" :href="getPhones.length ? 'tel:' + getPhones[0] :''">
          <svg width="24"
               height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_501_5054)">
              <path
                d="M23.6665 19.9568L20.2828 23.3134C19.7833 23.8257 19.1212 23.9992 18.4765 24C15.625 23.9146 12.9298 22.5139 10.7168 21.0757C7.0844 18.4331 3.75148 15.1564 1.65968 11.1966C0.857404 9.53618 -0.0839564 7.41756 0.0059836 5.56418C0.0140036 4.86698 0.201884 4.18272 0.692904 3.73332L4.07662 0.351297C4.77926 -0.246363 5.45876 -0.0397228 5.9084 0.656437L8.63062 5.81848C8.91714 6.43002 8.75278 7.08526 8.3253 7.52224L7.07866 8.76824C7.0017 8.87366 6.9527 8.99366 6.95146 9.12426C7.4295 10.9747 8.87772 12.6802 10.1571 13.854C11.4364 15.0277 12.8116 16.6179 14.5966 16.9944C14.8173 17.0559 15.0875 17.0776 15.2454 16.9308L16.6956 15.456C17.1955 15.0771 17.9173 14.8919 18.451 15.2017H18.4764L23.3866 18.1005C24.1075 18.5524 24.1826 19.4256 23.6665 19.9568Z"
                fill="white"/>
            </g>
            <defs>
              <clipPath id="clip0_501_5054">
                <rect width="24" height="24" fill="white"/>
              </clipPath>
            </defs>
          </svg>
        </a>
      </div>
      <div class="mobileMenu-center">
        <div class="header-lang">
          <a
            class="header-lang__link"
            :class="{'header-lang__current': $i18n.locale === 'ru'}"
            @click="() => changeLanguage('ru')"
          >
            ру
          </a>
          <a
            class="header-lang__link "
            :class="{'header-lang__current': $i18n.locale === 'en'}"
            @click="() => changeLanguage('en')"
          >
            eng
          </a>
        </div>
        <nav class="mobileMenu-nav js-scrolls">
          <ul>
            <li><a href="#menuMobileButton" @click="closeTopMenu">{{ $t('menuSection') }}</a></li>
            <li><a href="#gallerySection" @click="closeTopMenu">{{ $t('menuGallery') }}</a></li>
            <li><a href="#delivery" @click="closeTopMenu">{{ $t('menuDelivery') }}</a></li>
            <li><a href="#contact" @click="closeTopMenu">{{ $t('menuContact') }}</a></li>
            <li><a href="#parking" @click="closeTopMenu">{{ $t('menuParking') }}</a></li>
          </ul>
        </nav>
      </div>
      <div class="mobileMenu-address">{{ $t('siteAddress') }}</div>
      <div class="mobileMenu-blocks">
        <div class="mobileMenu-block"><a v-for="phone in getPhones" :key="phone" :href="'tel:' + phone" class="mobileMenu-link" >{{phone}}</a></div>
        <div class="mobileMenu-block"><a class="mobileMenu-email" :href="'mailto:' + getEmail">{{ getEmail }}</a>
        </div>
      </div>
    </div>
  </div>
  <div class="container main-alert__container">
    <div class="main-alert">
      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
        <path d="M12.4999 2.08325C15.2626 2.08325 17.9121 3.18072 19.8656 5.13422C21.8191 7.08773 22.9166 9.73725 22.9166 12.4999C22.9166 15.2626 21.8191 17.9121 19.8656 19.8656C17.9121 21.8191 15.2626 22.9166 12.4999 22.9166C9.73725 22.9166 7.08773 21.8191 5.13422 19.8656C3.18072 17.9121 2.08325 15.2626 2.08325 12.4999C2.08325 9.73725 3.18072 7.08773 5.13422 5.13422C7.08773 3.18072 9.73725 2.08325 12.4999 2.08325ZM12.4999 3.64575C10.1516 3.64575 7.89956 4.5786 6.23908 6.23908C4.5786 7.89956 3.64575 10.1516 3.64575 12.4999C3.64575 14.8482 4.5786 17.1003 6.23908 18.7608C7.89956 20.4212 10.1516 21.3541 12.4999 21.3541C14.8482 21.3541 17.1003 20.4212 18.7608 18.7608C20.4212 17.1003 21.3541 14.8482 21.3541 12.4999C21.3541 10.1516 20.4212 7.89956 18.7608 6.23908C17.1003 4.5786 14.8482 3.64575 12.4999 3.64575ZM12.4999 10.9374C12.8958 10.9374 13.2187 11.2291 13.2708 11.6145V11.7187L13.2812 17.4478C13.295 17.655 13.2259 17.8592 13.0892 18.0155C12.9524 18.1718 12.7592 18.2674 12.552 18.2812C12.3448 18.295 12.1406 18.2259 11.9843 18.0892C11.828 17.9524 11.7325 17.7592 11.7187 17.552V11.7187C11.7187 11.2812 12.0624 10.9374 12.4999 10.9374ZM12.4999 7.29159C12.7762 7.29159 13.0411 7.40133 13.2365 7.59668C13.4318 7.79203 13.5416 8.05699 13.5416 8.33325C13.5416 8.60952 13.4318 8.87447 13.2365 9.06982C13.0411 9.26517 12.7762 9.37492 12.4999 9.37492C12.2237 9.37492 11.9587 9.26517 11.7633 9.06982C11.568 8.87447 11.4583 8.60952 11.4583 8.33325C11.4583 8.05699 11.568 7.79203 11.7633 7.59668C11.9587 7.40133 12.2237 7.29159 12.4999 7.29159Z" fill="white"/>
      </svg>
      <div class="main-alert__wrapper">
        <span class="main-alert__title">{{ $t('bronTitle') }}</span>
        <span class="main-alert__descr">{{ $t('bronDescr') }}</span>
      </div>
    </div>
  </div>
  <main class="main" style="background-image: url(https://wp.prihol.ru/wp-content/themes/phbar/img/ph-bg.jpg);" v-if="withoutBanner === false">
    <div class="container">
      <div class="main-wrapper">
        <div class="main-item" v-if="getContacts">
          <h1 class="main-title" v-html="$t('siteTitle')"></h1>
          <div class="main-subtitle">{{ getAddress }}</div>
          <div class="main-text" v-for="i in getContacts.raspisanie" :key="i.time">
            <p>{{ i.time }}</p>
            <p>{{ $i18n.locale == 'ru' ? i.day_ru : i.day_en}}</p>
          </div>
        </div>
        <div class="main-mobileBtn" id="menuMobileButton" @click="toggleMobileMenu">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M7.75 9.75H5C3.895 9.75 3 8.855 3 7.75V5C3 3.895 3.895 3 5 3H7.75C8.855 3 9.75 3.895 9.75 5V7.75C9.75 8.855 8.855 9.75 7.75 9.75Z"
                  stroke="#0B1315" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M19 9.75H16.25C15.145 9.75 14.25 8.855 14.25 7.75V5C14.25 3.895 15.145 3 16.25 3H19C20.105 3 21 3.895 21 5V7.75C21 8.855 20.105 9.75 19 9.75Z"
                  stroke="#0B1315" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M7.75 21H5C3.895 21 3 20.105 3 19V16.25C3 15.145 3.895 14.25 5 14.25H7.75C8.855 14.25 9.75 15.145 9.75 16.25V19C9.75 20.105 8.855 21 7.75 21Z"
                  stroke="#0B1315" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M19 21H16.25C15.145 21 14.25 20.105 14.25 19V16.25C14.25 15.145 15.145 14.25 16.25 14.25H19C20.105 14.25 21 15.145 21 16.25V19C21 20.105 20.105 21 19 21Z"
                  stroke="#0B1315" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <span>{{ $t('openMenu') }}</span>
        </div>
      </div>
    </div>
  </main>
  <div class="main-alert__main-wrapper">
    <div class="container">
      <div class="main-alert main-alert--mobile">
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
          <path d="M12.4999 2.08325C15.2626 2.08325 17.9121 3.18072 19.8656 5.13422C21.8191 7.08773 22.9166 9.73725 22.9166 12.4999C22.9166 15.2626 21.8191 17.9121 19.8656 19.8656C17.9121 21.8191 15.2626 22.9166 12.4999 22.9166C9.73725 22.9166 7.08773 21.8191 5.13422 19.8656C3.18072 17.9121 2.08325 15.2626 2.08325 12.4999C2.08325 9.73725 3.18072 7.08773 5.13422 5.13422C7.08773 3.18072 9.73725 2.08325 12.4999 2.08325ZM12.4999 3.64575C10.1516 3.64575 7.89956 4.5786 6.23908 6.23908C4.5786 7.89956 3.64575 10.1516 3.64575 12.4999C3.64575 14.8482 4.5786 17.1003 6.23908 18.7608C7.89956 20.4212 10.1516 21.3541 12.4999 21.3541C14.8482 21.3541 17.1003 20.4212 18.7608 18.7608C20.4212 17.1003 21.3541 14.8482 21.3541 12.4999C21.3541 10.1516 20.4212 7.89956 18.7608 6.23908C17.1003 4.5786 14.8482 3.64575 12.4999 3.64575ZM12.4999 10.9374C12.8958 10.9374 13.2187 11.2291 13.2708 11.6145V11.7187L13.2812 17.4478C13.295 17.655 13.2259 17.8592 13.0892 18.0155C12.9524 18.1718 12.7592 18.2674 12.552 18.2812C12.3448 18.295 12.1406 18.2259 11.9843 18.0892C11.828 17.9524 11.7325 17.7592 11.7187 17.552V11.7187C11.7187 11.2812 12.0624 10.9374 12.4999 10.9374ZM12.4999 7.29159C12.7762 7.29159 13.0411 7.40133 13.2365 7.59668C13.4318 7.79203 13.5416 8.05699 13.5416 8.33325C13.5416 8.60952 13.4318 8.87447 13.2365 9.06982C13.0411 9.26517 12.7762 9.37492 12.4999 9.37492C12.2237 9.37492 11.9587 9.26517 11.7633 9.06982C11.568 8.87447 11.4583 8.60952 11.4583 8.33325C11.4583 8.05699 11.568 7.79203 11.7633 7.59668C11.9587 7.40133 12.2237 7.29159 12.4999 7.29159Z" fill="white"/>
        </svg>
        <div class="main-alert__wrapper">
          <span class="main-alert__title">{{ $t('bronTitle') }}</span>
          <span class="main-alert__descr">{{ $t('bronDescr') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'App',
  props: {
    withoutBanner: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toggleMobileMenu () {
      this.$store.dispatch('SET_MOBILE_MENU', !this.$store.state.mobileMenu)
    },
    toggleMobileTopMenu () {
      this.$store.dispatch('SET_MOBILE_MENU_TOP', !this.$store.state.mobileMenuTop)
    },
    closeTopMenu () {
      this.$store.dispatch('SET_MOBILE_MENU_TOP', false)
    },
    changeLanguage (lang) {
      this.$i18n.locale = lang
    }
  },
  computed: {
    ...mapGetters(['getContacts']),
    getFloor () {
      return this.getContacts?.floor + this.$i18n.t('siteFloor')
    },
    getAddress () {
      if (this.$i18n.locale === 'en') {
        return this.getContacts?.address_en
      }
      return this.getContacts?.address
    },
    getPhones () {
      if (this.getContacts?.phones) {
        return Object.values(this.getContacts.phones)
      }
      return []
    },

    getEmail () {
      return this.getContacts?.email || ''
    },
    getWorkDays () {
      if (this.$i18n.locale === 'en') {
        return this.getContacts?.address_en
      }
      return this.getContacts?.raspisanie || []
    }
  }

}
</script>
