<template>
  <section class="gallery" id="gallerySection">
    <div class="container gallery-container">
      <div class="gallery-wrapper">
        <div class="gallery-tabs">
          <div class="gallery-tabs__item" :class="{ 'active': tab == 'photo' }" @click.stop="tab = 'photo'"
            style="cursor: default">{{ $t('photoTitle') }}</div>
          <div class="gallery-tabs__item" :class="{ 'active': tab == 'video' }" @click.stop="tab = 'video'"
            style="cursor: default">{{ $t('videoTitle') }}</div>
        </div>
        <div class="gallery-items">
          <div class="gallery-item" v-if="tab == 'photo'">
            <div class="gallery-item__flex">
              <div class="gallery-item__block">
                <a class="gallery-item__link" href="https://wp.prihol.ru/wp-content/uploads/2023/07/gallery-1-1.jpg"
                  data-fancybox="gallery">
                  <picture>
                    <source
                      srcset="https://wp.prihol.ru/wp-content/webp-express/webp-images/uploads/2023/07/gallery-1-1.jpg.webp"
                      type="image/webp">
                    <img src="https://wp.prihol.ru/wp-content/uploads/2023/07/gallery-1-1.jpg" alt=""
                      class="lazyload webpexpress-processed" loading="lazy">
                  </picture>
                </a>
              </div>
              <div class="gallery-item__block">
                <a class="gallery-item__link" href="https://wp.prihol.ru/wp-content/uploads/2023/07/gallery-2-1.jpg"
                  data-fancybox="gallery">
                  <picture>
                    <source
                      srcset="https://wp.prihol.ru/wp-content/webp-express/webp-images/uploads/2023/07/gallery-2-1.jpg.webp"
                      type="image/webp">
                    <img src="https://wp.prihol.ru/wp-content/uploads/2023/07/gallery-2-1.jpg" alt=""
                      class="lazyload webpexpress-processed" loading="lazy">
                  </picture>
                </a>
              </div>
              <div class="gallery-item__block">
                <a class="gallery-item__link" href="https://wp.prihol.ru/wp-content/uploads/2023/07/gallery-4-1.jpg"
                  data-fancybox="gallery">
                  <picture>
                    <source
                      srcset="https://wp.prihol.ru/wp-content/webp-express/webp-images/uploads/2023/07/gallery-4-1.jpg.webp"
                      type="image/webp">
                    <img src="https://wp.prihol.ru/wp-content/uploads/2023/07/gallery-4-1.jpg" alt=""
                      class="lazyload webpexpress-processed" loading="lazy">
                  </picture>
                </a>
              </div>
              <div class="gallery-item__block">
                <a class="gallery-item__link" href="https://wp.prihol.ru/wp-content/uploads/2023/07/gallery-3-1.jpg"
                  data-fancybox="gallery">
                  <picture>
                    <source
                      srcset="https://wp.prihol.ru/wp-content/webp-express/webp-images/uploads/2023/07/gallery-3-1.jpg.webp"
                      type="image/webp">
                    <img src="https://wp.prihol.ru/wp-content/uploads/2023/07/gallery-3-1.jpg" alt=""
                      class="lazyload webpexpress-processed" loading="lazy">
                  </picture>
                </a>
              </div>
              <div class="gallery-item__block">
                <a class="gallery-item__link" href="https://wp.prihol.ru/wp-content/uploads/2023/07/gallery-5.jpg"
                  data-fancybox="gallery">
                  <picture>
                    <source
                      srcset="https://wp.prihol.ru/wp-content/webp-express/webp-images/uploads/2023/07/gallery-5.jpg.webp"
                      type="image/webp">
                    <img src="https://wp.prihol.ru/wp-content/uploads/2023/07/gallery-5.jpg" alt=""
                      class="lazyload webpexpress-processed" loading="lazy">
                  </picture>
                </a>
              </div>
              <div class="gallery-item__block">
                <a class="gallery-item__link" href="https://wp.prihol.ru/wp-content/uploads/2023/07/gallery-6.jpg"
                  data-fancybox="gallery">
                  <picture>
                    <source
                      srcset="https://wp.prihol.ru/wp-content/webp-express/webp-images/uploads/2023/07/gallery-6.jpg.webp"
                      type="image/webp">
                    <img src="https://wp.prihol.ru/wp-content/uploads/2023/07/gallery-6.jpg" alt=""
                      class="lazyload webpexpress-processed" loading="lazy">
                  </picture>
                </a>
              </div>
              <div class="gallery-item__block">
                <a class="gallery-item__link" href="https://wp.prihol.ru/wp-content/uploads/2023/07/gallery-7.jpg"
                  data-fancybox="gallery">
                  <picture>
                    <source
                      srcset="https://wp.prihol.ru/wp-content/webp-express/webp-images/uploads/2023/07/gallery-7.jpg.webp"
                      type="image/webp">
                    <img src="https://wp.prihol.ru/wp-content/uploads/2023/07/gallery-7.jpg" alt=""
                      class="lazyload webpexpress-processed" loading="lazy">
                  </picture>
                </a>
              </div>
              <div class="gallery-item__block">
                <a class="gallery-item__link" href="https://wp.prihol.ru/wp-content/uploads/2023/07/gallery-8.jpg"
                  data-fancybox="gallery">
                  <picture>
                    <source
                      srcset="https://wp.prihol.ru/wp-content/webp-express/webp-images/uploads/2023/07/gallery-8.jpg.webp"
                      type="image/webp">
                    <img src="https://wp.prihol.ru/wp-content/uploads/2023/07/gallery-8.jpg" alt=""
                      class="lazyload webpexpress-processed" loading="lazy">
                  </picture>
                </a>
              </div>
            </div>
            <!--            <div class="gallery-item__slider swiper">-->
            <!--              <div class="swiper-wrapper">-->
            <Swiper class="gallery-item__slider" :loop="true" :slides-per-view="3" :space-between="8">
              <SwiperSlide class="swiper-slide menu-item" v-for="item in swiperImg" :key="item">
                <a class="gallery-item__sliderLink" data-fancybox="gallery-mobile"
                  :href="`https://wp.prihol.ru/wp-content/uploads/2023/07/${item}.jpg`">
                  <picture>
                    <source
                      :srcset="`https://wp.prihol.ru/wp-content/webp-express/webp-images/uploads/2023/07/${item}.jpg.webp`"
                      type="image/webp">
                    <img :src="`https://wp.prihol.ru/wp-content/webp-express/webp-images/uploads/2023/07/${item}.jpg`" />
                  </picture>
                </a>
              </SwiperSlide>
            </Swiper>
            <!--              </div>-->
            <!--            </div>-->
          </div>
          <div class="gallery-item">
            <Swiper class="gallery-item__slider" :loop="true" :slides-per-view="1" :space-between="8">
              <SwiperSlide class="swiper-slide menu-item" >
                <a class="gallery-item__sliderLink gallery-item__video"  data-fancybox="gallery-mobile"  :data-src="require('@/assets/video/video0.mp4')">
                  <picture>
                    <img src="@/assets/video0_preview.png" />
                  </picture>
                </a>
              </SwiperSlide>
              <SwiperSlide class="swiper-slide menu-item">
                <a class="gallery-item__sliderLink gallery-item__video" data-fancybox="gallery-mobile" :data-src="require('@/assets/video/video1.mp4')">
                  <picture>
                    <img src="@/assets/video1_preview.png" />
                  </picture>
                </a>
              </SwiperSlide>
            </Swiper>
            <div class="gallery-video">
              <div class="video-container">
                <a class="" :data-src="require('@/assets/video/video0.mp4')"
                  data-fancybox="gallery">
                  <picture class="rounded-video">

                    <img src="@/assets/video0_preview.png" alt=""
                      class="lazyload webpexpress-processed" loading="lazy">
                  </picture>
                </a>
              </div>
              <div class="video-container">
                <a class="rounded-video" :data-src="require('@/assets/video/video1.mp4')"
                  data-fancybox="gallery">
                  <picture >
                    <img src="@/assets/video1_preview.png" alt=""
                      class="lazyload webpexpress-processed" loading="lazy">
                  </picture>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Fancybox } from '@fancyapps/ui/dist/fancybox/fancybox.esm'

export default {
  components: {
    Swiper,
    SwiperSlide
  },
  data () {
    return {
      tab: 'photo',
      swiperImg: ['gallery-1-1', 'gallery-2-1', 'gallery-3-1', 'gallery-4-1', 'gallery-5', 'gallery-6', 'gallery-7', 'gallery-8']
    }
  },
  mounted () {
    const fancyboxSettings = {
      hideScrollbar: true,
      infinite: true,
      dragToClose: false,
      trapFocus: false,
      placeFocusBack: false
    }

    Fancybox.bind('[data-fancybox="gallery"]', fancyboxSettings)
    Fancybox.bind('[data-fancybox="gallery-mobile"]', fancyboxSettings)
  },
  methods: {

  }
}
</script>
<style>
.gallery-video {
  display: flex;
  flex-wrap: wrap; /* allows the video containers to wrap onto the next line on smaller screens */
  gap: 1rem; /* gives some space between the video containers */
  justify-content: space-between; /* spaces the videos equally */
}
.video-container {
  flex: 1 0 calc(50% - 0.5rem); /* allows each video to take up close to 50% of the width minus half the gap */
  max-width: 50%; /* ensures they don't take more than 50% */
  position: relative; /* relative positioning for potential fancybox styles or other absolute positioned elements */
}
.rounded-video img {
  width: 100%;
  height: auto; /* maintains the aspect ratio */
  display: block; /* removes any default whitespace below the image */
  border-radius: 15px; /* gives rounded corners; adjust as needed */
}
</style>
