<template>
    <div class="catalog-title__wrapper">
      <div class="container">
        <h2 class="catalog-title">{{ $t('menuSection') }}</h2>
      </div>
    </div>
    <div class="catalog-top catalog_menu">
      <div class="container">
        <div v-if="!isLoading" class="catalog-prev">
          <div class="catalog-next__circle">
            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd" clip-rule="evenodd"
                d="M4.71922 1.51746C5.01151 1.22725 5.4854 1.22725 5.77769 1.51746L12.2808 7.97433C12.4211 8.1137 12.5 8.30271 12.5 8.4998C12.5 8.6969 12.4211 8.88591 12.2808 9.02528L5.77769 15.4821C5.4854 15.7724 5.01151 15.7724 4.71922 15.4821C4.42693 15.1919 4.42693 14.7214 4.71922 14.4312L10.6931 8.4998L4.71922 2.56841C4.42693 2.2782 4.42693 1.80767 4.71922 1.51746Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
        <div v-if="!isLoading" class="catalog-next">
          <div class="catalog-next__circle">
            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd" clip-rule="evenodd"
                d="M4.71922 1.51746C5.01151 1.22725 5.4854 1.22725 5.77769 1.51746L12.2808 7.97433C12.4211 8.1137 12.5 8.30271 12.5 8.4998C12.5 8.6969 12.4211 8.88591 12.2808 9.02528L5.77769 15.4821C5.4854 15.7724 5.01151 15.7724 4.71922 15.4821C4.42693 15.1919 4.42693 14.7214 4.71922 14.4312L10.6931 8.4998L4.71922 2.56841C4.42693 2.2782 4.42693 1.80767 4.71922 1.51746Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
        <div class="catalog-slider" :class="{'catalog-slider--loading': isLoading}">
          <div v-if="isLoading" class="menu-item">
            <div class="catalog-top__category">
              <span class="catalog-top__skeleton-text"></span>
            </div>
            <div class="catalog-top__category">
              <span class="catalog-top__skeleton-text"></span>
            </div>
            <div class="catalog-top__category">
              <span class="catalog-top__skeleton-text"></span>
            </div>
            <div class="catalog-top__category">
              <span class="catalog-top__skeleton-text"></span>
            </div>
            <div class="catalog-top__category">
              <span class="catalog-top__skeleton-text"></span>
            </div>
          </div>
          <swiper
            v-else
            :modules="swiperModules"
            :navigation="{
              'prevEl': '.catalog-prev',
              'nextEl': '.catalog-next'
            }"
            :breakpoints="swiperBreakpoints"
            :free-mode="{ enabled: true, sticky: true }"
          >
            <swiper-slide class="swiper-slide menu-item" v-for="item in menuItems" :key="item.term_id">
              <a
                class="catalog-top__category"
                @click="() => onMenuItemClick(item)"
              >
                {{item.name}}
              </a>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
</template>

<script>
import { Navigation, FreeMode } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'

import Axios from 'axios'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    Swiper,
    SwiperSlide
  },
  emits: ['menuClick'],
  watch: {
    '$i18n.locale' () {
      this.loadMenuItems()
    }
  },
  data () {
    return {
      isLoading: true,
      swiperBreakpoints: {
        1200: {
          slidesPerView: 6
        },
        1024: {
          slidesPerView: 5
        }
      },
      swiperModules: [Navigation, FreeMode]
    }
  },
  mounted () {
    this.loadMenuItems()
    this.selectActualCategory()
  },
  methods: {
    ...mapActions(['setTopMenuCategoryId', 'setCategoryName', 'fetchDishes', 'setCurrentCategoryId', 'setCurrentMenuItemId', 'setMenuItems', 'setTopCategoryName']),
    loadMenuItems () {
      this.isLoading = true

      let url = 'https://wp.prihol.ru/wp-json/phbar/v1/menu/'

      if (this.$i18n.locale === 'en') {
        url = 'https://wp.prihol.ru/en/wp-json/phbar/v1/menu/'
      }

      Axios.get(url)
        .then(response => {
          this.setMenuItems(response.data)
          // this.menuItems = response.data
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    selectActualCategory () {
      let url = 'https://wp.prihol.ru/wp-json/phbar/v1/actual_category/'

      if (this.$i18n.locale === 'en') {
        url = 'https://wp.prihol.ru/en/wp-json/phbar/v1/actual_category/'
      }

      Axios.get(url)
        .then(response => {
          this.onMenuItemClick(response.data, false)
        })
    },
    onMenuItemClick (item, withScroll = true) {
      const id = item.term_id
      const categoryName = item.cat_name

      if (withScroll) {
        this.$emit('menuClick')
      }
      this.setCurrentCategoryId(null)
      this.setCurrentMenuItemId(null)
      this.setTopMenuCategoryId(id)
      this.setTopCategoryName(categoryName)
      this.setCategoryName(categoryName)
      this.fetchDishes(id)
      this.setCurrentCategoryId(id)
    }
  },
  computed: {
    ...mapGetters(['menuItems'])
  }
}
</script>
