<template>
  <section class="parking" id="parking">
    <div class="container">
      <div class="parking-wrapper">
        <h2 class="parking-title xs">{{ $t('parkingTitle') }}</h2>
        <p class="parking-description xs">{{ $t('parkingInfo') }}</p>
        <div class="parking-box">
          <h2 class="parking-title xs-hide">{{ $t('parkingTitle') }}</h2>
          <p class="parking-description xs-hide">{{ $t('parkingInfo') }}</p>
          <div class="parking-grid">
            <div class="parking-grid__item">
              {{ $t('parkingFreeB') }} <br> {{ $t('parkingFreeV') }}  <span>{{ $t('parkingFree') }}</span>
            </div>
            <div class="parking-grid__item">
              03:00 – 07:00 <span>300 Р</span>
            </div>
            <div class="parking-grid__item">
              с 07:00 <span v-html="$t('parkingClose')"></span>
            </div>
          </div>
        </div>
        <div class="parking-image">
          <img src="https://wp.prihol.ru/wp-content/uploads/2022/12/frame-124219-1.png">
        </div>
      </div>
    </div>
  </section>
</template>

<script></script>
